#products .box {
    min-height: 600px;
    border-radius: 18px;
    margin-bottom: 40px;
    /* box-shadow: 0px 10px 15px red; */
    box-shadow: 0px 0px 20px rgba(73, 78, 92, 0.1);
    background: #fff;
    transition: 0.4s;
    display: flex;
    flex-direction: column;
}

#products .box:hover {
    box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
}
