#clients {
    padding: 30px 0;
}

#clients img {
    max-width: 20%;
    opacity: 0.5;
    transition: 0.3s;
    padding: 15px 0;
}

#clients img:hover {
    opacity: 1;
}

.image-client {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 1rem 0;
    list-style-type: none;
}
.slick-dots li {
    margin: 0 0;
}
.slick-dots li button {
    display: block;
    width: 12px !important;
    height: 12px !important;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: #ddd !important;
    text-indent: -9999px;
}
.slick-dots li.slick-active button {
    background-color: var(--secondary-color) !important;
}

@media (max-width: 768px) {
    #clients img {
        max-width: 45%;
    }
    #clients .image-client {
        flex-direction: column;
    }
}
