@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i|Raleway:300,400,500,700,800|Montserrat:300,400,700');

html {
    scroll-behavior: smooth;
}

body {
    background: #fff;
    color: #808080;
    font-family: 'Open Sans', sans-serif;
}

a {
    color: #50d8af;
    transition: 0.5s;
}

a:hover,
a:active,
a:focus {
    color: #51d8af;
    outline: none;
    text-decoration: none;
}

p {
    padding: 0;
    margin: 0 0 30px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    margin: 0 0 20px 0;
    padding: 0;
}

section {
    scroll-margin-top: 82px;
}

:root {
    --primary-color: #0c2e8a;
    --secondary-color: #0c2e8a;
}
