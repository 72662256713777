#about {
    padding: 60px 0 30px 0;
}

#about .about-img {
    overflow: hidden;
}

#about .about-img img {
    width: 130%;
}

@media (max-width: 768px) {
    #about .about-img {
        height: auto;
    }
    #about .about-img img {
        margin-left: 0;
        padding-bottom: 30px;
    }
}

/* #about .content h2 {
    color: #0f4c81;
    font-weight: 700;
    font-size: 36px;
    font-family: 'Raleway', sans-serif;
} */

#about .content h3 {
    color: #555;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    font-style: italic;
}

#about .content p {
    line-height: 26px;
    font-size: 18px;
    font-weight: 400;
}
#about .content p strong {
    color: var(--secondary-color);
}

#about .content p:last-child {
    margin-bottom: 0;
}

#about .content i {
    font-size: 16px;
    padding-right: 4px;
    color: var(--secondary-color);
}

#about .content ul {
    list-style: none;
    padding: 0;
}

#about .content ul li {
    padding-bottom: 10px;
}
