#footer {
    background: #081e5b;
    padding: 0 0 30px 0;
    font-size: 14px;
}

#footer .copyright {
    text-align: center;
    padding-top: 30px;
    font-weight: 600;
    color: #fff;
    font-size: 16px;
}

#footer .credits {
    text-align: center;
    font-size: 13px;
    color: #555;
}

#footer .credits a {
    color: #0f4c81;
}
