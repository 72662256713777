#topbar {
    background: #fff;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
}

#topbar .contact-info a {
    line-height: 1;
    color: #555;
}

#topbar .contact-info a:hover {
    color: var(--secondary-color);
}

#topbar .contact-info i {
    color: var(--secondary-color);
    padding: 4px;
}

#topbar .contact-info .fa-phone {
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #e9e9e9;
}

#topbar .social-links a {
    color: #555;
    padding: 4px 12px;
    display: inline-block;
    line-height: 1px;
    border-left: 1px solid #e9e9e9;
}

#topbar .social-links a:hover {
    color: var(--secondary-color);
}

#topbar .social-links a:first-child {
    border-left: 0;
}
