.hide {
    position: relative !important;
}

.dropbtn {
    background-color: #4caf50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.nav-menu .languages:hover .dropdown-content {
    display: flex !important;
}
/* @keyframes opacityF {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
} */

.dropdown-content {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 0px 16px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 50px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s linear;
    min-width: 150px;
    right: 0;
    padding: 6px 14px !important;
    border-radius: 8px;
}

/* .dropdown .flags {

} */

.dropdown .flagImg {
    width: 44px;
    height: 30px;
    padding-right: 4px;
}

.dropdown .vi,
.dropdown .vi-VN {
    background-image: url(../../asset/img/vietnamese.png);
    background-size: 100%;
    background-repeat: no-repeat;
    width: 50px;
    height: 33.33px;
}

.dropdown .en,
.dropdown .en-US {
    background-image: url(../../asset/img/english.png);
    background-size: 100%;
    background-repeat: no-repeat;
    width: 50px;
    height: 25px;
}

.dropdown .jp,
.dropdown .ja-JP {
    background-image: url(../../asset/img/japanese.webp);
    background-size: 100%;
    background-repeat: no-repeat;
    width: 50px;
    height: 33.31px;
    border: 1px solid #ccc;
}

.dropdown-content button {
    display: flex;
    align-items: center;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 20px;
    text-decoration: none;
    background-color: #fff;
    border: none;
    transition: 0.3s;
    text-align: left;
    padding: 6px 0;
}

/* .dropdown-content button:nth-child(1),
.dropdown-content button:nth-child(2) {
    border-bottom: 1px solid #ccc;
} */

.dropdown-content button .enFlag {
    width: 40px;
    height: 24px;
}

.dropdown-content button .vnFlag {
    width: 40px;
    height: 28px;
}

.dropdown-content button .jpFlag {
    width: 40px;
    border: 1px solid #ccc;
    height: 26.65px;
}

.dropdown-content button p {
    margin-left: 4px;
    font-size: 18px;
    color: #212529;
    transition: all 0.3s;
}

.dropdown-content button:hover p {
    color: var(--secondary-color);
}

.dropdown-content button:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.dropdown-content button:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.dropdown:hover .dropdown-content {
    display: flex;
    flex-direction: column;
    visibility: visible;
    opacity: 1;
}

.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

.langshow {
    display: flex !important;
}

#header {
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    display: flex;
    align-items: center;
    padding: 20px 0;
    height: 100px;
    width: 100%;
    transition: all 0.5s;
    z-index: 997;
    background: #fff;
    box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.06);
}

#header #logo {
    width: 25%;
}

#header #logo h1 {
    font-size: 42px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

#header #logo h1 a {
    color: #0f4c81;
    line-height: 1;
    display: inline-block;
    text-decoration: none;
}

#header #logo h1 a span {
    color: var(--secondary-color);
}

#header #logo img {
    width: 140%;
    padding: 0;
    margin: 0;
}

#header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.langlist {
    display: none;
}

@media (max-width: 768px) {
    #header {
        padding: 20px 0;
        height: 74px;
    }
    #header #logo h1 {
        font-size: 34px;
    }
    #header #logo img {
        max-height: 50px;
    }
}
@media (max-width: 992px) {
    #header #logo img {
        width: 150%;
    }

    /* .dropdown .vi {
        width: 45px;
    }

    .dropdown .jp {
        width: 40px;
        height: 26.65px;
    }

    .dropdown .en {
        width: 44.44px;
        height: 26.66px;
    } */
    #header .nav-menu li a,
    .dropdown-content button p,
    #header .languages,
    .dropdown-content button {
        font-size: 13px;
    }
    .dropdown-content {
        min-width: 120px;
        /* top: 30px; */
    }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Nav Menu Essentials */

.nav-menu {
    display: flex;
    align-items: center;
}
.nav-menu,
.nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav-menu ul {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: 99;
}

.nav-menu li {
    position: relative;
    white-space: nowrap;
}

.nav-menu > li {
    float: left;
}

.nav-menu li:hover > ul,
.nav-menu li.sfHover > ul {
    display: block;
}

.nav-menu ul ul {
    top: 0;
    left: 100%;
}

.nav-menu ul li {
    min-width: 100px;
}

.menu-has-children .drop-down::after {
    content: '\f107';
    position: absolute;
    right: -5px;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
}

/* Nav Menu Arrows */
.sf-arrows .sf-with-ul {
    padding-right: 22px;
}

.sf-arrows .sf-with-ul:after {
    content: '\f107';
    position: absolute;
    right: 8px;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
}

.sf-arrows ul .sf-with-ul:after {
    content: '\f105';
}

/* Nav Meu Container */
#nav-menu-container {
    float: right;
    margin: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;
}
/* .navigation-menu ul {
    display: flex;
    justify-content: center;
    align-items: center;
} */

@media (max-width: 768px) {
    .navigation-menu ul {
        position: fixed;
        top: 72px;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: white;
    }
    .navigation-menu li {
        text-align: center;
        margin: 0;
    }
    .navigation-menu li a {
        color: black;
        width: 100%;
        padding: 1.5rem 0;
    }
    .navigation-menu li:hover {
        color: var(--secondary-color);
    }
    .nav-menu {
        /* previous styles */
        display: flex;
        justify-content: start;
        flex-direction: column;
        transform: translateX(100%);
        transition: transform 0.5s ease-in;
    }
    .nav-menu.expanded {
        transform: translateX(0%);
    }
    .nav-menu.expanded li {
        margin-left: 0;
    }
    .nav-menu a,
    .nav-menu .languages {
        font-size: 24px !important;
        background-size: 14px;
        justify-content: center;
    }
    .dropdown-content {
        top: 50px;
        width: 100%;
    }

    .dropdown-content button {
        font-size: 24px;
    }
    .languages {
        justify-content: space-between;
        min-width: 140px;
    }

    .dropdown-content button p {
        font-size: 18px;
        margin-left: 8px;
    }
}

/* Nav Meu Styling */
/* .nav-menu {
    clip-path: none;
}

.nav-menu li {
    opacity: 1;
    transform: none;
} */

.nav-menu li a {
    padding: 10px 8px;
    text-decoration: none;
    display: inline-block;
    color: #000;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 20px;
    outline: none;
    transition: 0.2s all;
    cursor: pointer;
}

.nav-menu li {
    color: #1c2467;
}

.nav-menu li:hover {
    color: #1c9dd9;
}

.nav-menu .languages {
    display: flex;
    align-items: center;
    margin: 10px 8px;
    border: none;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 20px;
    height: 33.33px;
    transition: 0.3s;
    outline: none;
    background: none;
}
.nav-menu .languages svg {
    padding-left: 6px;
}

.nav-menu li:hover > .languages option {
    color: #555 !important;
    border: none;
}

.nav-menu li:hover > a,
.nav-menu li:hover > .languages,
.nav-menu > .menu-active > a {
    /* color: var(--secondary-color); */
}

.nav-menu > li {
    margin-left: 6px;
}

.nav-menu ul {
    margin: 4px 0 0 0;
    padding: 4px;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    background: #fff;
}

.nav-menu ul::before {
    content: '';
    display: block;
    position: absolute;
    top: -11px;
    left: 0px;
    width: 50%;
    height: 12px;
    cursor: pointer;
}

.nav-menu ul li {
    transition: 0.3s;
}

.nav-menu ul li a {
    padding: 10px;
    color: #333;
    transition: 0.3s;
    display: block;
    font-size: 13px;
    text-transform: none;
    cursor: pointer;
}

.nav-menu ul li a:hover {
    color: var(--secondary-color) !important;
}

.nav-menu ul li:hover > a {
    color: var(--secondary-color);
}

.nav-menu ul ul {
    margin: 0;
}

/* Mobile Nav Toggle */
#mobile-nav-toggle {
    /* position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
    margin: 20px 20px 0 0; */
    border: 0;
    background: none;
    font-size: 24px;
    display: none;
    transition: all 0.4s;
    outline: none;
    cursor: pointer;
}

#mobile-nav-toggle i {
    color: #555;
}

@media (max-width: 768px) {
    #mobile-nav-toggle {
        display: inline;
    }
}

/* Mobile Nav Styling */
#mobile-nav {
    position: fixed;
    top: 0;
    padding-top: 18px;
    bottom: 0;
    z-index: 998;
    background: rgba(52, 59, 64, 0.9);
    left: -260px;
    width: 260px;
    overflow-y: auto;
    transition: 0.4s;
}

#mobile-nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

#mobile-nav ul li {
    position: relative;
}

#mobile-nav ul li a {
    color: #fff;
    font-size: 16px;
    overflow: hidden;
    padding: 10px 22px 10px 15px;
    position: relative;
    text-decoration: none;
    width: 100%;
    display: block;
    outline: none;
}

#mobile-nav ul li a:hover {
    color: #fff;
}

#mobile-nav ul li li {
    padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
    position: absolute;
    right: 0;
    z-index: 99;
    padding: 15px;
    cursor: pointer;
    color: #fff;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
    color: var(--secondary-color);
}

#mobile-nav ul .menu-item-active {
    color: var(--secondary-color);
}

#mobile-body-overly {
    width: 100%;
    height: 100%;
    z-index: 997;
    top: 0;
    left: 0;
    position: fixed;
    /* overflow: hidden; */
    background: rgba(52, 59, 64, 0.9);
    display: none;
}

/* Mobile Nav body classes */
body.mobile-nav-active {
    overflow: hidden;
}

body.mobile-nav-active #mobile-nav {
    left: 0;
}

body.mobile-nav-active #mobile-nav-toggle {
    color: #fff;
}
