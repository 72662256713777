.card-container {
    margin-bottom: 40px;
}
.product-image img {
    width: 84%;
    margin-top: 10px;
}

.product-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-child {
    margin-bottom: 14px;
}

.product-title {
    font-weight: 700;
    margin-bottom: 18px;
    font-size: 34px;
    color: var(--primary-color);
}

.product-name {
    background: var(--primary-color);
    border: 0;
    border-radius: 30px;
    padding: 6px 30px;
    color: #fff;
    transition: 0.4s;
    font-weight: 600;
    font-size: 18px;
    cursor: context-menu !important;
}

.product-description {
    font-size: 18px;
    margin-bottom: 6px;
}

.product-description i {
    padding-right: 4px;
    color: var(--primary-color);
}
