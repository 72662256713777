#intro {
    width: 100%;
    height: 60vh;
    position: relative;
    background: url('/src/asset/img/intro-carousel/1.jpg') no-repeat;
    background-size: cover;
}

#intro .intro-content {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

#intro .intro-content h2 {
    color: #0f4c81;
    margin-bottom: 30px;
    font-size: 64px;
    font-weight: 700;
}

#intro .intro-content h2 span {
    color: #50d8af;
    text-decoration: underline;
}
/* .slide-image {
    position: fixed;
} */

@media (max-width: 767px) {
    #intro .intro-content h2 {
        font-size: 34px;
    }
    .slide-wrap,
    .slide-image {
        height: 30vh !important;
    }
}
@media (min-width: 768px) and (max-width: 1023px) {
    .slide-wrap,
    .slide-image {
        height: 42vh !important;
    }
}

#intro .intro-content .btn-get-started,
#intro .intro-content .btn-projects {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 32px;
    border-radius: 2px;
    transition: 0.5s;
    margin: 10px;
    color: #fff;
}

#intro .intro-content .btn-get-started {
    background: #0f4c81;
    border: 2px solid #0f4c81;
}

#intro .intro-content .btn-get-started:hover {
    background: none;
    color: #0f4c81;
}

#intro .intro-content .btn-projects {
    background: #50d8af;
    border: 2px solid #50d8af;
}

#intro .intro-content .btn-projects:hover {
    background: none;
    color: #50d8af;
}

#intro #intro-carousel {
    z-index: 8;
}

/* #intro #intro-carousel::before {
    content: '';
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 7;
} */

#intro #intro-carousel .item {
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-transition-property: opacity;
    transition-property: opacity;
}
