.banner {
    position: relative;
}

.banner .image {
    background-color: #ccc;
    height: 400px;
    width: 100%;
}

.banner .text-center {
    font-size: 40px;
    line-height: normal;
    font-weight: bold;
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.policy-container {
    margin-bottom: 100px;
}

.policy-container .title {
    margin-top: 30px;
    font-size: 30px;
    font-weight: 700;
    color: var(--primary-color);
}

.policy-container .description {
    font-size: 16px;
}

.policy-container h2 {
    color: var(--primary-color);
    font-size: 20px;
    font-weight: 600;
}
