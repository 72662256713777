.Toastify__toast-container--top-right {
    top: 5em !important;
}
#contact {
    padding: 30px 0;
}

#contact .contact-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 500px;
    margin-bottom: 20px;
    background-color: #081e5b;
    color: #fff;
    border-radius: 18px;
    padding: 27px 20px;
}

#contact .contact-info i {
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: var(--primary-color);
    border-radius: 50%;
    width: 60px;
    height: 60px;
}

#contact .contact-info address,
#contact .contact-info p {
    margin-bottom: 0;
}

#contact .contact-info h3 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: bold;
    text-transform: uppercase;
}

#contact .contact-info a {
    color: #fff;
}

#contact .contact-info a:hover {
    color: var(--secondary-color);
}

#contact .contact-address,
#contact .contact-phone,
#contact .contact-email {
    margin-bottom: 28px;
}

@media (max-width: 768px) {
    #contact .contact-address,
    #contact .contact-phone,
    #contact .contact-email {
        padding: 20px 0;
    }

    #contact .form .btn-send {
        text-align: center;
    }
}

@media (max-width: 992px) {
    #contact .form .btn-send {
        text-align: center;
    }
}

#contact #google-map {
    height: 290px;
    margin-bottom: 20px;
}

@media (max-width: 576px) {
    #contact #google-map {
        margin-top: 20px;
    }
}

#contact .form #sendmessage {
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    display: none;
    text-align: center;
    padding: 15px;
    font-weight: 600;
    margin-bottom: 15px;
}

#contact .form #errormessage {
    color: red;
    display: none;
    border: 1px solid red;
    text-align: center;
    padding: 15px;
    font-weight: 600;
    margin-bottom: 15px;
}

#contact .form #sendmessage.show,
#contact .form #errormessage.show,
#contact .form .show {
    display: block;
}

#contact .form .validation {
    color: red;
    display: none;
    margin: 0 0 20px;
    font-weight: 400;
    font-size: 13px;
}

#contact .form label {
    color: #808080;
    margin-bottom: 4px;
}

#contact .form input,
#contact .form textarea {
    padding: 10px 14px;
    border-radius: 4px;
    box-shadow: none;
    font-size: 20px;
    background-color: #f5f6f9;
    border-color: #f5f6f9;
}

#contact .form button[type='submit'] {
    background: var(--secondary-color);
    border: 0;
    border-radius: 30px;
    padding: 12px 26px;
    color: #fff;
    transition: 0.4s;
    cursor: pointer;
    margin: 0 8px;
    font-weight: 600;
    font-size: 18px;
}

#contact .form button[type='submit']:hover {
    background: #2dc899;
}

#contact .form .errormessage {
    color: red;
    font-size: 14px;
}

#contact .form .errormessage p {
    margin-bottom: 0 !important;
}

#contact .btn-send {
    text-align: right;
}
