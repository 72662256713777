/* Sections Header
--------------------------------*/
.section-header {
    margin-bottom: 30px;
}

.section-header h2 {
    font-size: 32px;
    color: var(--primary-color);
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    padding-bottom: 20px;
}

.section-header h2::before {
    content: '';
    position: absolute;
    display: block;
    width: 60px;
    height: 4px;
    background: var(--secondary-color);
    bottom: 0;
    left: 0;
}

.section-header p {
    padding: 0;
    margin: 0;
}
